<template>
    <div
        class="border rounded border-info px-1 d-flex flex-column bg-custom py-1"
    >
        <div class="col-12">
            <div
                v-if="'id' in reference.product && reference.productId"
                class="d-flex flex-column w-100"
            >
                <strong>
                    {{ reference.product.name | cut(50) }}&nbsp;
                    {{
                        reference.active === undefined
                            ? ""
                            : reference.active
                            ? "🟢"
                            : "⚪"
                    }}
                </strong>

                <div>
                    <small>
                        Prod.
                        <router-link
                            :to="`/app/products/detail/${reference.productId}`"
                            class="btn-a p-0"
                        >
                            {{
                                reference.product.sku
                                    ? `SKU ${reference.product.sku}`
                                    : `ID ${reference.productId}`
                            }}
                        </router-link>
                        |
                        <router-link
                            :to="
                                merchant
                                    ? `/app/inventories/${merchant.merchantType}/${merchant.merchantId}/detail/${reference.id}`
                                    : `/app/inventories/detail/${reference.id}`
                            "
                            class="btn-a p-0"
                        >
                            Ref
                        </router-link>
                        <router-link
                            :to="`/app/products/detail/${reference.productId}?reference=${reference.id}&active=references`"
                            class="btn-a p-0"
                        >
                            {{
                                reference.barcode
                                    ? `Cód. ${reference.barcode}`
                                    : `ID ${reference.id}`
                            }}
                        </router-link>
                    </small>
                </div>
            </div>
            <div v-else class="d-flex flex-column w-100">
                <span class="p-0">
                    {{ reference.product.name }}
                </span>
                <small> Servicio / P.A. </small>
            </div>
        </div>
        <div class="d-flex justify-content-center p-2">
            <button class="p-0" @click="goToMainPage(reference.product.slug)">
                <img
                    class="w-100 h-100"
                    :src="reference.images[0].url | image"
                    :alt="reference.images[0].alt"
                    loading="lazy"
                />
            </button>
        </div>
        <div
            class="d-flex justify-content-md-between flex-md-row flex-column align-items-center"
        >
            <div
                v-if="enabled"
                id="actions"
                class="d-flex w-100 justify-content-end mt-2 align-items-center"
            >
                <vs-tooltip v-if="enabledDiscount" border>
                    <vs-checkbox
                        v-model="availableDiscount"
                        warn
                        @input="changeAvailableDiscount"
                    />
                    <template #tooltip> Aplicar Descuento 🤑</template>
                </vs-tooltip>

                <b-form-spinbutton
                    v-model="quantity"
                    class="mx-2"
                    max="100"
                    min="1"
                    size="sm"
                    inline
                    @input="changeQuantity"
                >
                    <template #decrement>➖</template>
                    <template #increment>➕</template>
                </b-form-spinbutton>

                <vs-button danger circle icon @click="remove"> 🗑 </vs-button>
            </div>
        </div>

        <div class="d-flex flex-wrap">
            <div
                v-if="reference.color"
                class="col-6 d-flex flex-md-column justify-content-between pr-2"
            >
                <strong class="">Color </strong>
                <div class="d-flex align-items-center">
                    <small class="text-capitalize">
                        {{ reference.color }}
                    </small>
                    <div
                        class="ml-1 len-3 rounded-circle border border-secondary"
                        :style="{
                            'background-color': reference.colorRef
                        }"
                    ></div>
                </div>
            </div>
            <div
                v-if="reference.size"
                class="col-6 d-flex flex-md-column justify-content-between pr-2"
            >
                <strong class="">Tamaño</strong>
                <small class="text-capitalize">
                    {{ reference.size }}
                </small>
            </div>
            <div
                class="col-6 d-flex flex-md-column justify-content-between pr-2"
            >
                <strong class="">Cantidad </strong>
                <small
                    :class="{
                        'text-through font-weight-bold': quantity === 0
                    }"
                >
                    {{ quantity }}
                </small>
            </div>
            <div
                class="col-6 d-flex flex-md-column justify-content-between pr-2"
            >
                <strong class="">$ Unidad </strong>
                <small>
                    {{ reference.priceOffer | money }}
                </small>
            </div>
            <div
                v-if="Number.isInteger(reference.appliedDiscountPerReference)"
                class="d-flex col-6 flex-md-column justify-content-between pr-2"
            >
                <strong class="">$ Descuento u. </strong>
                <small>
                    {{ reference.appliedDiscountPerReference | money }}
                </small>
            </div>

            <div class="col-12 mt-2 d-flex pr-2">
                <strong class="text-primary"> $ Referencias: &nbsp; </strong>
                <span
                    class="font-weight-bold"
                    :class="{
                        'text-through ': quantity === 0
                    }"
                >
                    {{
                        ((reference.priceOffer -
                            (reference.appliedDiscountPerReference || 0)) *
                            quantity)
                            | money
                    }}
                </span>
            </div>

            <div
                v-if="reference.cost"
                class="col-12 mt-2 d-flex pr-2 align-items-end"
            >
                <span> Costo: &nbsp; </span>
                <small>
                    {{ reference.cost | money }}
                </small>
            </div>
            <div
                v-if="reference.priceAgreedMerchant"
                class="col-12 mt-2 d-flex pr-2 align-items-end"
            >
                <span> $ Acordado con comercio: &nbsp; </span>
                <small>
                    {{ reference.priceAgreedMerchant | money }}
                </small>
            </div>
            <div
                v-if="reference.priceOfferMerchant"
                class="col-12 mt-2 d-flex pr-2 align-items-end"
            >
                <span> $ Venta en comercio: &nbsp; </span>
                <small>
                    {{ reference.priceOfferMerchant | money }}
                </small>
            </div>
            <div
                v-if="reference.stockMerchant"
                class="col-12 my-2 d-flex pr-2 align-items-end"
            >
                <span> Unidades en comercio: &nbsp; </span>
                <small>
                    {{ reference.stockMerchant }}
                </small>
            </div>
        </div>

        <div v-if="availableDiscount && enabled" class="mt-4 pr-2">
            <input-money-component
                v-model="appliedDiscountPerReference"
                border
                enabled
                label="Descuento neto por referencia"
                icon="💲"
                :message-error="
                    appliedDiscountPerReference > reference.priceOffer * 0.6
                        ? 'Descuento no debe exceder el 60% del valor de la referencia'
                        : ''
                "
                @blur="blurAppliedDiscountPerReference"
                @focus="focusInput"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

import { DEFAULT_IMAGE_URL } from "@/common/constants";

import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "ReferenceTableItems2",
    components: { InputMoneyComponent },
    props: {
        reference: {
            type: Object,
            required: true,
            default: () => ({
                id: 20,
                size: "pequeño",
                color: "rojo",
                colorRef: "#f00",
                quantity: 1,
                price: 10000,
                priceOffer: 10000,
                priceAgreed: 1000,
                appliedDiscountPerReference: 0,
                images: [
                    {
                        id: 1,
                        url: DEFAULT_IMAGE_URL,
                        alt: "alt"
                    }
                ],
                product: {
                    id: "",
                    name: "Cargando",
                    shortDescription: "Cargando..."
                }
            })
        },
        enabled: {
            default: () => false,
            type: Boolean,
            required: false
        },
        enabledDiscount: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    emits: ["update", "remove", "focus"],
    data: () => ({
        quantity: 0,
        appliedDiscountPerReference: 0,
        inventories: [],
        availableDiscount: false,
        stockActive: 0
    }),
    watch: {
        reference(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.reference);
    },
    methods: {
        ...mapActions("inventories", ["listInventoryByReference"]),
        goToMainPage(slug) {
            if (this.reference.active) {
                window
                    .open(`${this.$marketplaceUrl}/${slug}`, "_blank")
                    .focus();
            }
        },
        remove() {
            this.$emit("remove", { id: this.reference.id });
        },
        blurAppliedDiscountPerReference() {
            this.$emit("update", {
                id: this.reference.id,
                quantity: this.quantity,
                appliedDiscountPerReference: this.appliedDiscountPerReference
            });
        },
        focusInput() {
            this.$emit("focus");
        },
        setInput(value) {
            this.quantity = Number(value.quantity);
            this.appliedDiscountPerReference =
                Number(value.appliedDiscountPerReference) || 0;
            this.availableDiscount = this.appliedDiscountPerReference > 0;
        },
        changeQuantity(value) {
            this.$emit("update", {
                id: this.reference.id,
                quantity: value,
                appliedDiscountPerReference: this.appliedDiscountPerReference
            });
        },
        changeAvailableDiscount(value) {
            if (!value) {
                this.appliedDiscountPerReference = 0;
                this.blurAppliedDiscountPerReference();
            }
        }
    }
};
</script>
