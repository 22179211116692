<template>
    <div class="w-100 h-100">
        <div class="d-flex justify-content-center">
            <h1 class="h2">
                Dividir y asignar proveedores a orden
                <a
                    :href="`/app/orders/detail/${itemComponent.id}`"
                    class="text-primary"
                >
                    <strong> #{{ itemComponent.id }} </strong>
                </a>
            </h1>
        </div>
        <div id="info-container" class="d-flex flex-wrap">
            <div
                class="offset-lg-7 col-lg-5 col-12 mt-2 d-flex align-items-center justify-content-between"
            >
                <small for="swDateBetween">
                    ¿ Necesitas ayuda para asignar proveedores ?
                </small>
                <vs-button
                    icon
                    border
                    :active="enableHelp"
                    @click="enableHelp = !enableHelp"
                >
                    ℹ️
                </vs-button>
            </div>
        </div>
        <div v-show="enableHelp">
            <div class="col-10 mx-auto rounded border border-success py-4">
                <ul class="list-group">
                    <li class="py-2 px-1">
                        1. Verifica si el pedido es a domicilio o en comercio.
                        En caso de que exista inventario en la comercio donde se
                        va a recoger el pedido, asigna los productos a la
                        tienda.
                    </li>
                    <li class="py-2 px-1">
                        2. Verifica que los productos tenga inventario
                        habilitado en algún comercio. Los productos que tienen
                        inventario disponible tiene un border color azul, de lo
                        contrario el borde es color rojo.
                    </li>
                    <li class="py-2 px-1">
                        3. Selecciona el prooducto, automáticamente se
                        habilitarán unicamente los comercioes que tenga
                        inventario del producto seleccionado, adicionalmente se
                        mostrarán las unidades que tiene actualmente el
                        comercioes y el precio de venta al público.
                    </li>
                    <li class="py-2 px-1">
                        4. Una vez todos los productos hayan sido asignados haz
                        click en el botón "Dividir y asignar proveedoreses"
                        ubicado en la parte inferior de la página.
                    </li>
                    <li class="py-2 px-1">
                        5. Confirma si quieres decrementar inventario de cada
                        almace y fragmentar la orden o solo quieres fragmentar
                        la ordén. El sistema creará una orden madre y le
                        asociará cada orden hila a cada comercio.
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="hasLoadedBase" class="my-4">
            <div v-if="itemComponent.supplyMerchant">
                <div
                    class="list-group-item list-group-item-warning px-3 rounded"
                >
                    <span>
                        Orden asignada a
                        {{
                            itemComponent.supplyMerchant.merchantType
                                | merchantInfo("type")
                        }}
                        {{
                            `${itemComponent.supplyMerchant.name} Dirección ${itemComponent.supplyMerchant.address} / ${itemComponent.supplyMerchant.addressComplement}`
                        }}
                    </span>
                </div>
            </div>
            <div class="mt-2">
                <div v-if="itemComponent.location">
                    <span class="h5">
                        Domicilio para entregar a
                        <strong>
                            {{
                                itemComponent.location.address
                                    | address
                                    | cut(28)
                            }}
                        </strong>
                        , barrio {{ itemComponent.location.neighborhood }} de
                        {{ itemComponent.location.city }}
                    </span>
                </div>
                <div v-if="itemComponent.pickupMerchant">
                    <span class="h5">
                        Entregar en la
                        {{
                            itemComponent.pickupMerchant.merchantType
                                | merchantInfo("type")
                        }}
                        <strong class="font-italic">
                            {{ itemComponent.pickupMerchant.name }}
                        </strong>
                        ubicada
                        {{
                            itemComponent.pickupMerchant.address
                                | address
                                | cut(28)
                        }}, barrio
                        {{ itemComponent.pickupMerchant.neighborhood }} de
                        {{ itemComponent.pickupMerchant.city }}
                    </span>
                </div>
            </div>

            <div class="d-flex overflow-x-auto mt-4">
                <div class="mr-1">
                    <div
                        class="border rounded border-secondary w-72 h-100 px-1 pt-3"
                    >
                        <div>
                            <h3>🛍 Productos</h3>
                            <span>
                                Mueve los productos de la orden a cada tienda.
                            </span>
                        </div>

                        <Container
                            class="h-75"
                            group-name="order"
                            :get-child-payload="getReferencesPayload()"
                            drag-class="border-warning"
                            drop-class="border-secondary border"
                            :drop-placeholder="$data.$dropPlaceholderOptions"
                            @drag-start="onStartDrag"
                            @drag-end="onEndDrag"
                            @drop="onDropReferenceOfReferences"
                        >
                            <Draggable
                                v-for="item in references"
                                :key="item.id"
                            >
                                <reference-table-items-2
                                    :class="
                                        !item.merchants.length &&
                                        'border-danger'
                                    "
                                    class="my-2"
                                    :reference="item"
                                />
                            </Draggable>
                        </Container>
                    </div>
                </div>

                <div
                    v-for="column in available"
                    :key="`${column.merchantType}:${column.merchantId}`"
                    class="mr-1"
                >
                    <div
                        class="border rounded border-secondary w-72 px-1 pt-3 h-100"
                    >
                        <div>
                            <h3 class="text-primary text-capitalize">
                                {{
                                    column.merchantType
                                        | merchantInfo("icon", "type")
                                }}
                                <span
                                    :class="{
                                        'font-italic':
                                            itemComponent.pickupMerchant &&
                                            column.merchantType ===
                                                itemComponent.pickupMerchant
                                                    .merchantType &&
                                            itemComponent.pickupMerchant
                                                .merchantId ===
                                                column.merchantId
                                    }"
                                >
                                    {{ column.name }}
                                </span>
                            </h3>
                            <span>
                                {{ column.address | address | cut(28) }}
                                {{
                                    `${column.addressComplement}, barrio ${column.neighborhood} de ${column.city}`
                                }}
                            </span>

                            <div
                                v-if="column.stockMerchant"
                                class="my-2 d-flex flex-column"
                            >
                                <div>
                                    <strong> Stock comercio: </strong>
                                    {{ column.stockMerchant }}
                                </div>
                                <div>
                                    <strong> Precio comercio: </strong>
                                    {{ column.priceOfferMerchant | money }}
                                </div>
                                <div>
                                    <strong> Precio acordado: </strong>
                                    {{ column.priceAgreedMerchant | money }}
                                </div>
                            </div>
                        </div>
                        <Container
                            group-name="order"
                            class="h-75"
                            :get-child-payload="
                                getReferencesInMerchantPayload(column)
                            "
                            drag-class="border-warning"
                            drop-class="border-secondary"
                            :drop-placeholder="$data.$dropPlaceholderOptions"
                            @drop="(e) => onDropReferenceOfMerchant(column, e)"
                            @drag-end="onEndDrag"
                        >
                            <Draggable
                                v-for="item in column.children"
                                :key="item.id"
                            >
                                <reference-table-items-2
                                    class="my-2"
                                    :reference="item"
                                />
                            </Draggable>
                        </Container>
                    </div>
                </div>
                <div v-if="!available.length">
                    <div
                        class="border rounded border-danger w-72 px-4 pt-3 h-100 d-flex align-items-center"
                    >
                        <strong class="text-danger">
                            🚨 Producto sin inventario habilitado
                        </strong>
                    </div>
                </div>
            </div>

            <vs-button
                v-if="!references.length"
                class="my-5 col-9 mx-auto"
                border
                gradient
                @click="refactorOrderComponent"
            >
                <span class="h3"> Dividir y asignar proveedoreses </span>
            </vs-button>
        </div>
        <vs-dialog
            v-model="isOpenModalResult"
            scroll
            overflow-hidden
            auto-width
            @close="closeModalReult"
        >
            <template #header>
                <h2 class="text-primary">
                    Fragmentación orden #{{ id }} exitosa
                </h2>
            </template>
            <div class="con-content">
                <div v-if="orderGroupResult.orderGroupId">
                    <span class="my-2">Ordenes hijas</span>
                    <div class="d-flex justify-content-center">
                        <vs-button
                            :to="`/app/orders-groups/detail/${orderGroupResult.orderGroupId}`"
                            color="#f07325"
                        >
                            🧔 Orden madre #{{ orderGroupResult.orderGroupId }}
                        </vs-button>
                    </div>
                </div>
                <div>
                    <span class="my-2">Ordenes hijas</span>
                    <div class="d-flex flex-wrap justify-content-around">
                        <vs-button
                            v-for="orderChild in orderGroupResult.orders"
                            :key="orderChild.id"
                            :to="`/app/orders/detail/${orderChild.id}`"
                            color="#ee3f59"
                        >
                            👶 Orden hija #{{ orderChild.id }}
                        </vs-button>
                    </div>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";
import { mapActions, mapGetters } from "vuex";

import { DEFAULT_IMAGE_URL } from "@/common/constants";
import { applyDrag } from "@/common/lib/drag";

import ReferenceTableItems2 from "@/components/references/ReferenceTableItems2.vue";

export default {
    name: "OrdersRefactor",
    components: { Container, Draggable, ReferenceTableItems2 },
    data: () => ({
        itemComponent: {
            id: 76,
            orderGroupId: null,
            references: [
                {
                    id: 77,
                    size: "23",
                    color: "gris",
                    colorRef: "#999999",
                    product: {
                        id: 34,
                        sku: "RBLL003",
                        name: "Botas para La Lluvia de Hipopotamo",
                        slug: "botas-para-la-lluvia-de-hipopotamo"
                    },
                    images: [
                        {
                            id: 148,
                            url: DEFAULT_IMAGE_URL,
                            alt: "Bota de niño gris con hipopotamo foto principal",
                            order: {
                                order: 0
                            }
                        }
                    ],
                    references: null,
                    price: 150000,
                    quantity: 17,
                    priceOffer: 140000,
                    appliedDiscountPerReference: 0,
                    merchants: []
                }
            ],
            location: {
                cityCode: "11001",
                address: "cra 42 F # 78 C - 63 SUR",
                coordinates: {
                    latitude: 0,
                    longitude: 0
                },
                neighborhood: "",
                city: "Bogotá D.C."
            },
            pickupMerchant: null,
            merchantsInventories: [
                {
                    merchantId: "",
                    merchantType: "",
                    name: "",
                    address: "",
                    location: {
                        latitude: 4.574501,
                        longitude: -74.1163301
                    },
                    cityCode: "11001",
                    references: [
                        {
                            referenceId: 79,
                            priceMerchant: 140000,
                            priceOfferMerchant: 140000,
                            priceAgreedMerchant: 140000,
                            stockMerchant: 1,
                            minStockMerchant: 0
                        }
                    ]
                }
            ],
            supplyMerchant: null
        },
        references: [],
        available: [],
        availableTmp: [],
        loaderInstance: null,
        isLoading: false,
        hasLoadedBase: false,
        enableHelp: false,
        id: 0,
        $dropPlaceholderOptions: {
            className: "drop-preview",
            animationDuration: "150",
            showOnTop: true
        },
        orderGroupResult: {
            orderId: null,
            orderGroupId: null,
            orders: [{ id: null }]
        },
        isOpenModalResult: false
    }),
    computed: { ...mapGetters("control", ["backgroundColor"]) },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("id" in this.$route.params) {
            if (!(await verifyPageScope("order:Consultancy", "/app/orders")))
                return;
            this.id = Number(this.$route.params.id);
            return this.consultancyOrderComponent();
        }
    },
    methods: {
        ...mapActions("orders", ["consultancyOrder", "refactorOrder"]),
        async consultancyOrderComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            try {
                this.itemComponent = await this.consultancyOrder({
                    id: this.id
                });
                this.references = [...this.itemComponent.references];
                this.available = this.itemComponent.merchantsInventories.map(
                    (item) => ({
                        ...item,
                        children: []
                    })
                );
                this.hasLoadedBase = true;
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/orders");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async refactorOrderComponent() {
            const orders = this.available.filter(
                (item) => item.children.length > 0
            );
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: `Deseas fragmentar la orden ${this.id}`,
                text:
                    orders.length > 1
                        ? `Estas seguro que deseas fragementar la orden en ${orders.length} ordenes, recuerda que no puedes podrás editar los productos de las ordenes resultantes ni aplicar descuentos.`
                        : "Como la fragementación solo tiene un comercio se reasignará la orden, estas seguro que deseas hacer la operación.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (!dialogResult.isConfirmed) {
                return;
            }

            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            try {
                const result = await this.refactorOrder({
                    id: this.id,
                    orders: orders.map((item) => ({
                        merchantId: item.merchantId,
                        merchantType: item.merchantType,
                        references: item.children.map((item2) => ({
                            id: item2.id,
                            quantity: item2.quantity
                        }))
                    }))
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
                this.orderGroupResult = { ...result };
                this.isOpenModalResult = true;
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        getReferencesPayload() {
            return (index) => {
                return this.references[index]; // payload send in onDrop reference
            };
        },
        onDropReferenceOfReferences(dropResult) {
            if (
                dropResult.removedIndex !== null ||
                dropResult.addedIndex !== null
            ) {
                let references = [...this.references];
                references = applyDrag(references, dropResult).map((item) => {
                    delete item.priceAgreedMerchant;
                    delete item.priceAgreedMerchant;
                    delete item.priceOfferMerchant;
                    delete item.priceMerchant;
                    delete item.priceAgreedMerchant;
                    delete item.stockMerchant;
                    delete item.minStockMerchant;
                    return item;
                });
                this.references = references;
            }
        },
        getReferencesInMerchantPayload({ merchantId, repostoryType }) {
            return (index) => {
                return this.available.find(
                    (p) =>
                        p.merchantId === merchantId &&
                        p.repostoryType === repostoryType
                ).children[index]; // payload send in onDrop
            };
        },
        onDropReferenceOfMerchant({ merchantId, merchantType }, dropResult) {
            if (
                dropResult.removedIndex !== null ||
                dropResult.addedIndex !== null
            ) {
                const available = [...this.available];
                const column = available.find(
                    (p) =>
                        p.merchantId === merchantId &&
                        p.merchantType === merchantType
                );
                const columnIndex = available.findIndex(
                    (p) =>
                        p.merchantId === merchantId &&
                        p.merchantType === merchantType
                );
                const newColumn = { ...column };
                const children = applyDrag(newColumn.children, dropResult);
                newColumn.children = children.map((item) => {
                    const foundInventory = item.merchants.find(
                        (item2) =>
                            item2.merchantId === merchantId &&
                            item2.merchantType === merchantType
                    );
                    return {
                        ...item,
                        ...{
                            ...(foundInventory ? foundInventory : null)
                        }
                    };
                });
                available.splice(columnIndex, 1, newColumn);
                this.available = available;
            }
        },
        onEndDrag() {
            console.log("*** onEndDrag ***");
            const available = [...this.available, ...this.availableTmp].map(
                (item) => ({ ...item, stockMerchant: 0 })
            );
            this.availableTmp = [];
            this.available = [...available];
        },
        onStartDrag({ payload }) {
            console.log("*** onStartDrag ***");
            this.availableTmp = [];
            const available = [];
            this.available.forEach((item) => {
                const foundMerchant = payload.merchants.find(
                    (item2) =>
                        item2.merchantId === item.merchantId &&
                        item2.merchantType === item.merchantType
                );
                if (foundMerchant) {
                    available.push({ ...item, ...foundMerchant });
                } else {
                    this.availableTmp.push(item);
                }
            });
            this.available = [...available];
        },
        closeModalReult() {
            this.$router.push("/app/orders");
        }
    }
};
</script>
