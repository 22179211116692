<template>
    <div class="w-100">
        <vs-input
            v-show="showString"
            ref="inputMoneyString"
            v-model="priceString"
            type="text"
            class="vs-input-custom"
            :border="border"
            :state="stateEnabled"
            :shadow="border"
            :label="label"
            :disabled="!enabled"
            @input="formatPrice"
            @focus="onFocus"
            @click-icon="onClickIcon"
        >
            <template v-if="icon" #icon> {{ icon }} </template>
            <template #message-danger>
                <span v-show="messageError">
                    {{ messageError }}
                </span>
            </template>
        </vs-input>
        <vs-input
            v-show="!showString"
            ref="inputMoneyNumber"
            v-model="priceNumber"
            type="number"
            class="vs-input-custom"
            :border="border"
            :state="stateEnabled"
            :shadow="border"
            :label="label"
            :disabled="!enabled"
            :min="min"
            @input="formatPrice"
            @blur="onBlur"
            @keyup.enter="onEnter"
            @click-icon="onClickIcon"
        >
            <template v-if="icon" #icon> {{ icon }} </template>
            <template #message-danger>
                <span v-show="messageError">
                    {{ messageError }}
                </span>
            </template>
        </vs-input>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "InputMoneyComponent",
    model: {
        prop: "itemCurrent",
        event: "input"
    },
    props: {
        itemCurrent: {
            type: [Number, null],
            required: true,
            validator: (value) =>
                value === null ||
                value === undefined ||
                typeof value === "number",
            default: null
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        label: {
            default: () => "Precio",
            type: String,
            required: false
        },
        icon: {
            default: () => "",
            type: String,
            required: false
        },
        min: {
            default: () => 0,
            type: Number,
            required: false
        },
        messageError: {
            default: () => "",
            type: String,
            required: false
        },
        border: {
            default: () => false,
            type: Boolean,
            required: false
        }
    },
    emits: ["input", "blur", "click-icon", "enter"],
    data: () => ({
        priceString: "",
        priceNumber: 0,
        showString: true
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"]),
        stateEnabled() {
            return this.enabled ? this.stateInputDark : "";
        }
    },
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        },
        enabled() {
            if (this.priceString === "-") {
                this.priceString = "0";
            }
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        onFocus() {
            this.$emit("focus");
            this.showString = false;
            setTimeout(() => {
                try {
                    this.$refs.inputMoneyNumber.$el.children[0].children[0].focus();
                } catch (error) {
                    console.error("Error in focus %o", error);
                }
            }, 100);
        },
        onBlur() {
            this.$emit("blur");
            this.showString = true;
        },
        onClickIcon() {
            this.$emit("click-icon");
        },
        onEnter() {
            this.$emit("enter");
        },
        formatPrice() {
            this.priceNumber = Number(this.priceNumber || 0);
            this.priceString = this.$options.filters.currency(this.priceNumber);
            this.$emit("input", this.priceNumber);
        },
        setInput(value) {
            if (value === null) {
                this.priceString = "-";
                this.priceNumber = 0;
                return;
            }
            this.priceNumber = value;
            this.priceString = this.$options.filters.currency(value);
        }
    }
};
</script>
